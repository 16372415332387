<template>
    <div class="filters">
        <div class="filters__form">
            <input-component v-model="filters.id" placeholder="ID" size="small" outlined />

            <input-component v-model="filters.client_id" :placeholder="$t('statistics.table.filters.client_id')" size="small" outlined />

            <input-component v-model="filters.steam_id" placeholder="Steam ID" size="small" outlined />

            <select-component
                v-model="filters.status"
                :options="statuses"
                :placeholder="$t('statistics.table.filters.status')"
                size="small"
                outlined
            />

            <date-range-picker-input v-model="filters.date" size="small" outlined />

            <icon-button :disabled="disabled" theme="secondary" size="small" @click="submit">
                <search-icon />
            </icon-button>

            <icon-button :disabled="disabled" theme="secondary" size="small" @click="reset">
                <retry-arrow-icon />
            </icon-button>
        </div>

        <icon-button ref="modalButton" class="filters__modal-button" theme="secondary" size="small" @click="openModal">
            <search-icon />
        </icon-button>

        <filters-modal v-if="modalOpened" class="filters__modal" @close="closeModal" v-click-outside="closeModal">
            <input-component v-model="filters.id" placeholder="ID" outlined />

            <input-component v-model="filters.client_id" :placeholder="$t('statistics.table.filters.client_id')" outlined />

            <input-component v-model="filters.steam_id" placeholder="Steam ID" outlined />

            <select-component v-model="filters.status" :options="statuses" :placeholder="$t('statistics.table.filters.status')" outlined />

            <date-range-picker-input v-model="filters.date" outlined />

            <text-button :disabled="disabled" full @click="submit">
                {{ $t('statistics.table.filters.submit') }}
                <search-icon />
            </text-button>

            <text-button theme="secondary" :disabled="disabled" full @click="reset">
                {{ $t('statistics.table.filters.reset') }}
                <retry-arrow-icon />
            </text-button>
        </filters-modal>
    </div>
</template>

<script>
    import _ from 'underscore';
    import DateRangePickerInput from '@/components/inputs/DateRangePickerInput';
    import FiltersModal from '@/components/statistics/FiltersModal';
    import IconButton from '@/components/buttons/IconButton';
    import InputComponent from '@/components/inputs/InputComponent';
    import RetryArrowIcon from '@/components/icons/RetryArrowIcon';
    import SearchIcon from '@/components/icons/SearchIcon';
    import SelectComponent from '@/components/inputs/SelectComponent';
    import TextButton from '@/components/buttons/TextButton';

    export default {
        name: 'Filters',
        components: {
            DateRangePickerInput,
            FiltersModal,
            IconButton,
            InputComponent,
            RetryArrowIcon,
            SearchIcon,
            SelectComponent,
            TextButton,
        },
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            statuses: {
                type: Array,
                default: () => [],
            },
        },
        methods: {
            closeModal(event) {
                if (!event || event.target !== this.$refs.modalButton.$el) {
                    this.modalOpened = false;
                    this.reset();
                }
            },
            openModal() {
                this.modalOpened = true;
            },
            reset() {
                Object.keys(this.filters).forEach(key => (this.filters[key] = null));

                this.$emit('reset');
            },
            submit() {
                const filters = _.pick(this.filters, _.identity);

                !_.isEmpty(filters) ? this.$emit('submit', filters) : this.$emit('reset');
            },
        },
        data() {
            return {
                modalOpened: false,
                filters: {
                    client_id: null,
                    date: null,
                    id: null,
                    status: null,
                    steam_id: null,
                },
            };
        },
    };
</script>

<style lang="scss" scoped>
    .filters__form {
        display: none;
    }

    @media screen and (min-width: 75em) {
        .filters__modal,
        .filters__modal-button {
            display: none;
        }

        .filters__form {
            display: grid;
            grid-gap: 1rem;
            grid-template-columns: repeat(3, minmax(12rem, 20rem)) minmax(13.5rem, 20rem) minmax(18rem, 24.4rem) repeat(2, auto);
        }
    }
</style>
