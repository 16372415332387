<template>
    <div class="statistics-table">
        <div class="statistics-table__header">
            <div class="statistics-table__titles">
                <div
                    v-for="(tab, index) in tabs.items"
                    :class="{ 'statistics-table__title': true, 'statistics-table__title_active': index === tabs.selected }"
                    @click="tabs.selected = index"
                >
                    <component :is="tab.icon" />

                    <p>{{ tab.title }}</p>
                </div>
            </div>

            <filters
                v-if="isDepositsOpened"
                :disabled="isDepositsDisabled"
                :statuses="deposits.statuses"
                @submit="deposits.filters = $event"
                @reset="deposits.filters = null"
            />

            <filters
                v-if="isWithdrawsOpened"
                :disabled="isWithdrawsDisabled"
                :statuses="withdraws.statuses"
                @submit="withdraws.filters = $event"
                @reset="withdraws.filters = null"
            />
        </div>

        <div class="statistics-table__body">
            <template v-if="isDepositsOpened">
                <loader v-if="deposits.loading" absolute />

                <template v-else-if="deposits.items.length">
                    <deposit-table :items="deposits.items" />

                    <text-button
                        v-if="deposits.hasMore"
                        :loading="deposits.pagination.loading"
                        size="small"
                        theme="secondary"
                        full
                        @click="deposits.pagination.page++"
                    >
                        {{ $t('statistics.table.load') }}
                    </text-button>
                </template>

                <p v-else>{{ $t('statistics.table.empty') }}</p>
            </template>

            <template v-if="isWithdrawsOpened">
                <loader v-if="withdraws.loading" absolute />

                <template v-else-if="withdraws.items.length">
                    <withdraw-table :items="withdraws.items" />

                    <text-button
                        v-if="withdraws.hasMore"
                        :loading="withdraws.pagination.loading"
                        size="small"
                        theme="secondary"
                        full
                        @click="withdraws.pagination.page++"
                    >
                        {{ $t('statistics.table.load') }}
                    </text-button>
                </template>

                <p v-else>{{ $t('statistics.table.empty') }}</p>
            </template>
        </div>
    </div>
</template>

<script>
    import { CURRENCY_USD } from '@/constants/currencies';
    import { projectOrders, projectWithdraws } from '@/services/skinsPaymentApi';
    import { STATUSES as DEPOSITS_STATUSES } from '@/services/skinsPaymentApi/constants/orderStatus';
    import { STATUSES as WITHDRAW_STATUSES } from '@/services/skinsPaymentApi/constants/withdrawStatus';
    import DepositIcon from '@/components/icons/DepositIcon';
    import DepositTable from '@/components/statistics/DepositTable';
    import Filters from '@/components/statistics/Filters';
    import Loader from '@/components/Loader';
    import Status from '@/components/Status';
    import TableComponent from '@/components/tables/TableComponent';
    import TextButton from '@/components/buttons/TextButton';
    import Toast from '@/components/Toast';
    import WithdrawIcon from '@/components/icons/WithdrawIcon';
    import WithdrawTable from '@/components/statistics/WithdrawTable';

    export default {
        name: 'StatisticsTable',
        components: {
            DepositIcon,
            DepositTable,
            Filters,
            Loader,
            Status,
            TableComponent,
            TextButton,
            WithdrawIcon,
            WithdrawTable,
        },
        computed: {
            isDepositsOpened() {
                return 0 === this.tabs.selected;
            },
            isDepositsDisabled() {
                return this.deposits.loading || this.deposits.pagination.loading;
            },
            isWithdrawsOpened() {
                return 1 === this.tabs.selected;
            },
            isWithdrawsDisabled() {
                return this.withdraws.loading || this.withdraws.pagination.loading;
            },
        },
        methods: {
            fetchDeposits() {
                return projectOrders(this.$route.params.id, this.deposits.pagination.page, this.deposits.filters)
                    .then(({ data }) => {
                        this.deposits.items = this.deposits.items.concat(data.data);
                        this.deposits.hasMore = data.meta.current_page < data.meta.last_page;
                    })
                    .catch(() =>
                        this.$toast({
                            component: Toast,
                            props: {
                                type: 'error',
                                message: this.$t('statistics.errors.table'),
                            },
                        })
                    )
                    .finally(() => {
                        this.deposits.loading = false;
                        this.deposits.pagination.loading = false;
                    });
            },
            fetchWithdraws() {
                return projectWithdraws(this.$route.params.id, this.withdraws.pagination.page, this.withdraws.filters)
                    .then(({ data }) => {
                        this.withdraws.items = this.withdraws.items.concat(data.data.map(item => ({ ...item, currency: CURRENCY_USD })));
                        this.withdraws.hasMore = data.meta.current_page < data.meta.last_page;
                    })
                    .catch(() =>
                        this.$toast({
                            component: Toast,
                            props: {
                                type: 'error',
                                message: this.$t('statistics.errors.table'),
                            },
                        })
                    )
                    .finally(() => {
                        this.withdraws.loading = false;
                        this.withdraws.pagination.loading = false;
                    });
            },
        },
        watch: {
            'deposits.filters': function (current, old) {
                if (JSON.stringify(current) !== JSON.stringify(old)) {
                    this.deposits.loading = true;
                    this.deposits.items = [];
                    this.deposits.pagination.page = 1;
                    this.fetchDeposits();
                }
            },
            'deposits.pagination.page': function (page) {
                if (page > 1) {
                    this.deposits.pagination.loading = true;
                    this.fetchDeposits();
                }
            },
            'withdraws.filters': function (current, old) {
                if (JSON.stringify(current) !== JSON.stringify(old)) {
                    this.withdraws.loading = true;
                    this.withdraws.items = [];
                    this.withdraws.pagination.page = 1;
                    this.fetchWithdraws();
                }
            },
            'withdraws.pagination.page': function (page) {
                if (page > 1) {
                    this.withdraws.pagination.loading = true;
                    this.fetchWithdraws();
                }
            },
        },
        data() {
            return {
                tabs: {
                    selected: 0,
                    items: [
                        {
                            icon: 'deposit-icon',
                            title: this.$t('statistics.table.tabs.deposits'),
                        },
                        {
                            icon: 'withdraw-icon',
                            title: this.$t('statistics.table.tabs.withdraws'),
                        },
                    ],
                },
                deposits: {
                    filters: null,
                    hasMore: false,
                    items: [],
                    loading: true,
                    pagination: {
                        page: 1,
                        loading: false,
                    },
                    statuses: DEPOSITS_STATUSES,
                },
                withdraws: {
                    filters: null,
                    hasMore: false,
                    items: [],
                    loading: true,
                    pagination: {
                        page: 1,
                        loading: false,
                    },
                    statuses: WITHDRAW_STATUSES,
                },
            };
        },
        mounted() {
            this.fetchDeposits();
            this.fetchWithdraws();
        },
    };
</script>

<style lang="scss" scoped>
    .statistics-table {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        border-radius: 0.8rem;
        background-color: #10141f;
    }

    .statistics-table__header {
        display: flex;
        justify-content: space-between;
        grid-gap: 3rem;
        margin-bottom: 2rem;
        border-bottom: 0.1rem solid #161b29;
    }

    .statistics-table__titles {
        display: flex;
        align-items: center;
        grid-gap: 1.5rem;
    }

    .statistics-table__title {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        padding-bottom: 1.5rem;
        border-bottom: 0.1rem solid transparent;
        cursor: pointer;
        transition: border-bottom-color 0.3s;

        svg {
            width: 2rem;
            height: 2rem;
            margin-right: 0.5rem;
            fill: #353e54;
            transition: fill 0.3s;
        }

        p {
            color: #9aa2b7;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.3rem;
            font-weight: 325;
            transition: color 0.3s;
        }

        &:hover,
        &_active {
            svg {
                fill: #a3b8db;
            }

            p {
                color: #e5ecf9;
            }
        }

        &_active {
            border-bottom-color: #3760cb;
            pointer-events: none;
        }
    }

    .statistics-table__body {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        ::v-deep {
            .button {
                margin-top: 0.5rem;
            }

            > p {
                margin: auto;
            }
        }
    }

    .filters {
        padding-bottom: 1.5rem;
    }

    @media screen and (min-width: 22.5em) {
        .statistics-table__header {
            grid-gap: 4rem;
        }

        .statistics-table__titles {
            grid-gap: 2rem;
        }

        .statistics-table__title {
            svg {
                width: 2.2rem;
                height: 2.2rem;
                margin-right: 0.5rem;
            }

            p {
                font-size: 1.4rem;
            }
        }
    }

    @media screen and (min-width: 30em) {
        .statistics-table {
            padding: 1.5rem;
        }
    }

    @media screen and (min-width: 120em) {
        .statistics-table {
            padding: 1.5rem 3rem 3rem;
        }

        .statistics-table__title {
            svg {
                width: 2.8rem;
                height: 2.8rem;
                margin-right: 1rem;
            }

            p {
                font-size: 2rem;
            }
        }
    }
</style>
