<template>
    <app-layout>
        <loader v-if="loading" absolute />

        <template v-else>
            <div v-if="project" class="statistics__indicators">
                <indicator :title="$t('statistics.indicators.balance')" :value="project.project.balance" theme="success">
                    <template #icon>
                        <wallet-icon />
                    </template>
                </indicator>

                <indicator :title="$t('statistics.indicators.available')" :value="project.project.available_for_withdraw" theme="primary">
                    <template #icon>
                        <logout-icon />
                    </template>
                </indicator>

                <indicator :title="$t('statistics.indicators.today')" :value="project.today_income.sum">
                    <template #icon>
                        <check-case-icon />
                    </template>
                </indicator>

                <indicator :title="$t('statistics.indicators.average')" :value="project.today_income.average_deposit_amount">
                    <template #icon>
                        <exchange-case-icon />
                    </template>
                </indicator>
            </div>

            <card :class="{ statistics__chart: true, statistics__chart_loading: chart.loading }">
                <template #icon>
                    <pulse-icon />
                </template>

                <template #title>{{ $t('statistics.chart.title') }}</template>

                <template #controls>
                    <date-range-picker-input v-model="chart.date" size="small">
                        <template #prefix>
                            <calendar-icon />
                        </template>
                    </date-range-picker-input>
                </template>

                <loader v-if="chart.loading" />

                <statistics-chart v-else :labels="chartLabels" :datasets="chart.datasets" />
            </card>

            <statistics-table />
        </template>
    </app-layout>
</template>

<script>
    import { DateTime, Interval } from 'luxon';
    import { chartData, project } from '@/services/skinsPaymentApi';
    import AppLayout from '@/pages/layout/AppLayout';
    import CalendarIcon from '@/components/icons/CalendarIcon';
    import Card from '@/components/Card';
    import CheckCaseIcon from '@/components/icons/CheckCaseIcon';
    import DateRangePickerInput from '@/components/inputs/DateRangePickerInput';
    import ExchangeCaseIcon from '@/components/icons/ExchangeCaseIcon';
    import Indicator from '@/components/Indicator';
    import Loader from '@/components/Loader';
    import LogoutIcon from '@/components/icons/LogoutIcon';
    import PulseIcon from '@/components/icons/PulseIcon';
    import StatisticsChart from '@/components/charts/StatisticsChart';
    import StatisticsTable from '@/components/statistics/StatisticsTable';
    import Toast from '@/components/Toast';
    import WalletIcon from '@/components/icons/WalletIcon';

    const DEFAULT_DAYS_RANGE = 10;

    export default {
        name: 'Statistics',
        components: {
            AppLayout,
            CalendarIcon,
            Card,
            CheckCaseIcon,
            DateRangePickerInput,
            ExchangeCaseIcon,
            Indicator,
            Loader,
            LogoutIcon,
            PulseIcon,
            StatisticsChart,
            StatisticsTable,
            WalletIcon,
        },
        computed: {
            chartLabels() {
                return Interval.fromDateTimes(...this.chart.date)
                    .splitBy({ days: 1 })
                    .map(interval => interval.start.toFormat('dd.LL.yyyy'));
            },
        },
        methods: {
            fetchChartData() {
                this.chart.loading = true;

                return chartData(this.$route.params.id, ...this.chart.date.map(item => item.toISODate()))
                    .then(({ data }) => {
                        data = data.reduce((result, item) => {
                            result[DateTime.fromSQL(item.date).toFormat('dd.LL.yyyy')] = item;
                            return result;
                        }, {});

                        this.chart.datasets = [
                            {
                                label: () => this.$t('statistics.chart.datasets.income'),
                                data: this.chartLabels.map(date => (data[date] ? data[date].sum : 0)),
                                color: '#4ab990',
                            },
                            {
                                label: () => this.$t('statistics.chart.datasets.average_check'),
                                data: this.chartLabels.map(date => (data[date] ? data[date].avg : 0)),
                                color: '#3760cb',
                            },
                            {
                                label: () => this.$t('statistics.chart.datasets.deposits_count'),
                                data: this.chartLabels.map(date => (data[date] ? data[date].count : 0)),
                                color: '#73bdfa',
                            },
                        ];
                    })
                    .catch(() =>
                        this.$toast({
                            component: Toast,
                            props: {
                                type: 'error',
                                message: this.$t('statistics.errors.chart'),
                            },
                        })
                    )
                    .finally(() => (this.chart.loading = false));
            },
            fetchProject() {
                this.loading = true;

                return project(this.$route.params.id)
                    .then(({ data }) => (this.project = data))
                    .catch(() =>
                        this.$toast({
                            component: Toast,
                            props: {
                                type: 'error',
                                message: this.$t('statistics.errors.project'),
                            },
                        })
                    )
                    .finally(() => (this.loading = false));
            },
        },
        watch: {
            'chart.date': function (current, old) {
                if (current.reduce((result, item) => result + item.ts, '') !== old.reduce((result, item) => result + item.ts, '')) {
                    this.fetchChartData();
                }
            },
        },
        data() {
            return {
                chart: {
                    datasets: [],
                    date: [DateTime.now().startOf('day').minus({ days: DEFAULT_DAYS_RANGE }), DateTime.now().endOf('day')],
                    loading: false,
                },
                loading: true,
                project: null,
            };
        },
        mounted() {
            this.fetchChartData();
            this.fetchProject();
        },
    };
</script>

<style lang="scss" scoped>
    @import '~air-datepicker/air-datepicker.css';

    .statistics__indicators {
        display: grid;
        grid-gap: 0.5rem;
    }

    .statistics__chart {
        ::v-deep .card__controls {
            width: 24.4rem;
        }

        &_loading ::v-deep .card__body {
            height: 35rem;
        }
    }

    .statistics-table {
        flex-grow: 1;
    }

    ::v-deep {
        .app-layout__content {
            flex-grow: 1;
        }

        .app-layout__content,
        .scroll-container__content {
            display: flex;
            flex-direction: column;
        }
    }

    @media screen and (min-width: 30em) {
        .statistics__indicators {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media screen and (min-width: 60em) {
        .statistics__indicators {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media screen and (min-width: 120em) {
        .statistics__indicators {
            grid-gap: 1rem;
        }
    }
</style>
